html, body, #root {
  margin: 0;
  padding: 0;
  height: 100%;
}

*, *::before, *::after {
  box-sizing: border-box;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  font-family: 'Lora', sans-serif;
  background: linear-gradient(to bottom, #6a798b 0%, #000000 100%);
  color: #ffffff;
  overflow: hidden;
}

.App-content {
  width: 100%;
  overflow: auto;
}